import './Footer.css';

function Footer() {
    return (
        <>
            <div id='footer__container'>
                Designed and Built by Christy Chen<br />
                Powered by AWS
            </div>
        </>
    )
}

export default Footer;
